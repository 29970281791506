<template>
    <v-container fluid class="pa-0">
		<div v-if="authed == true" >
            <v-row>
                <v-container style="max-width:1200px">
                    <VendorReport />
                </v-container>
            </v-row>
		</div>
	</v-container>
</template>

<script>
import VendorReport from '@/components/report/dashboard-vendor.vue'
	export default {
		components: {
			VendorReport,
		},
		data() {
			return {
				mode: process.env.VUE_APP_MODE,
			}
		},
		computed: {
			authed: function () {
				return this.$store.getters.isLoggedIn
			}
		},

	}
</script>