import { render, staticRenderFns } from "./checkplan-form.vue?vue&type=template&id=2bde0d02"
import script from "./checkplan-form.vue?vue&type=script&lang=js"
export * from "./checkplan-form.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports