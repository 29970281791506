import axios from 'axios'
import store from './store'
import { VCardSubtitle } from 'vuetify/lib';
var Qs = require('qs');
var wormsApi = axios.create({
	baseURL: process.env.VUE_APP_API,
	timeout: 100000,
    paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'})
	//headers: {'Authorization': localStorage.getItem("storeToken")}
});
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'
var numberOfAjaxCAllPending = 0;
var numberOfAjaxCAllTotal = 0;
wormsApi.interceptors.request.use(
	function (config) {
		numberOfAjaxCAllPending++;
		numberOfAjaxCAllTotal++;
		store.dispatch('changeLoadingCurrent', numberOfAjaxCAllPending);
		store.dispatch('changeLoadingTotal', numberOfAjaxCAllTotal);
		store.dispatch('changeLoading', true);
		// if (process.env.VUE_APP_MODE == 'DEVELOPMENT') {
		// config.headers.request_startTime = new Date()
		// }
		const token = store.getters.jwt;
		if (token) config.headers.Authorization = store.getters.jwt;
		return config;
	},
	function (error) {
		store.dispatch('changeLoading', false);
		return Promise.reject(error);
	}
);
wormsApi.interceptors.response.use(function (response) {
	numberOfAjaxCAllPending--;
	if (numberOfAjaxCAllPending <= 0) {
        numberOfAjaxCAllPending = 0;
		numberOfAjaxCAllTotal = 0;
		store.dispatch('changeLoading', false);
    }
	store.dispatch('changeLoadingTotal', numberOfAjaxCAllTotal);
	if (process.env.VUE_APP_MODE == 'DEVELOPMENT') {
		// const start = response.config.headers.request_startTime
		// const end = new Date()
		// var seconds = (end.getTime() - start.getTime()) / 1000;
		// response.headers.request_duration = seconds
		// console.log('API:(/'+response.request.responseURL.replace(response.config.baseURL+'/', "") + '), Finished in ' + response.headers.request_duration+'s');
	}
    return response;
}, function (error) {
	numberOfAjaxCAllPending--;
	if (numberOfAjaxCAllPending <= 0) {
        numberOfAjaxCAllPending = 0;
		store.dispatch('changeLoadingCurrent', numberOfAjaxCAllPending);
		store.dispatch('changeLoadingTotal', numberOfAjaxCAllTotal);
		store.dispatch('changeLoading', false);
    }
    if (!error.response) {
        console.log('NETWORK ERROR')
    } else {
        if (error.response.status == '401') {
			store.dispatch('tokenError');
			store.dispatch('inspectToken');
        }
        if (error.response.status == '403') {
			store.dispatch('tokenError');
			store.dispatch('refreshToken');
        }
        if (error.response.status == '400' && error.response.statusText == 'Bad Request') {
			var result = Object.keys(error.response.data).map((key) => [(key)+': '+ error.response.data[key] +' <br/>']).join(' ');
			store.dispatch('apiError', "Compulsory field was not entered, or entered using incorrect data-type.<br/><br/>"+ result);
        }
        if (error.response.status == '423') {
			var lockedItems = '';
			for (let i = 0; i < error.response.data.protected.length; i++) {
				lockedItems += error.response.data.protected[i].model+' (ID:'+error.response.data.protected[i].id+')'+': '+ error.response.data.protected[i].title +' <br/>';
			}
			store.dispatch('apiError', error.response.data.message + '<br >' + lockedItems);
        }
        return Promise.reject(error)
    }
});
let configHtml = {
	header : {
		'Content-Type' : 'multipart/form-data'
	}
}
let configBlob = {
	header : {
	  'Content-Type' : 'application/octet-stream'
	}
}
var alphabet = [null, 'one', 'two', 'three', 'four', 'five']
// Assets
const assetSystem = {
	list: () => wormsApi.get('api/v1/asset/system/'),
	search: (params) => wormsApi.get('api/v1/asset/system/', { params }),
	get: (id) => wormsApi.get('api/v1/asset/system/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/asset/system/' + item.id + '/', item),
	new: (item) => wormsApi.post('api/v1/asset/system/', item),
	delete: (id) => wormsApi.delete('api/v1/asset/system/' + id + '/'),
}
const assetGeneric = {
	list: () => wormsApi.get('api/v1/asset/generic/'),
	search: (params) => wormsApi.get('api/v1/asset/generic/', { params }),
	get: (id) => wormsApi.get('api/v1/asset/generic/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/asset/generic/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/asset/generic/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/asset/generic/', item),
	delete: (id) => wormsApi.delete('api/v1/asset/generic/' + id + '/'),
}
const assetAsset = {
	list: () => wormsApi.get('api/v1/asset/asset/'),
	search: (params) => wormsApi.get('api/v1/asset/asset/', { params }),
	get: (id) => wormsApi.get('api/v1/asset/asset/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/asset/asset/' + item.id + '/', item),
	new: (item) => wormsApi.post('api/v1/asset/asset/', item),
	delete: (id) => wormsApi.delete('api/v1/asset/asset/' + id + '/'),
}
const assetGenericParameter = {
	list: () => wormsApi.get('api/v1/asset/generic-parameter/'),
	search: params => wormsApi.get('api/v1/asset/generic-parameter/', { params }),
	get: (id) => wormsApi.get('api/v1/asset/generic-parameter/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/asset/generic-parameter/' + item.id + '/', item),
	new: (item) => wormsApi.post('api/v1/asset/generic-parameter/', item),
	delete: (id) => wormsApi.delete('api/v1/asset/generic-parameter/' + id + '/'),
}
const assetAssetParameter = {
	list: () => wormsApi.get('api/v1/asset/asset-parameter/'),
	search: params => wormsApi.get('api/v1/asset/asset-parameter/', { params }),
	get: (id) => wormsApi.get('api/v1/asset/asset-parameter/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/asset/asset-parameter/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/asset/asset-parameter/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/asset/asset-parameter/', item),
	delete: (id) => wormsApi.delete('api/v1/asset/asset-parameter/' + id + '/'),
}
// Units
const unitUnit = {
	list: () => wormsApi.get('api/v1/unit/unit/'),
	search: params => wormsApi.get('api/v1/unit/unit/', { params }),
	get: (id) => wormsApi.get('api/v1/unit/unit/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/unit/unit/' + item.id + '/', item),
	new: (item) => wormsApi.post('api/v1/unit/unit/', item),
	delete: (id) => wormsApi.delete('api/v1/unit/unit/' + id + '/'),
}
const unitMeasurement = {
	list: () => wormsApi.get('api/v1/unit/measurement/'),
	search: params => wormsApi.get('api/v1/unit/measurement/', { params }),
	get: (id) => wormsApi.get('api/v1/unit/measurement/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/unit/measurement/' + item.id + '/', item),
	new: (item) => wormsApi.post('api/v1/unit/measurement/', item),
	delete: (id) => wormsApi.delete('api/v1/unit/measurement/' + id + '/'),
}
// Inspection
const inspectionCheckplan = {
	list: () => wormsApi.get('api/v1/inspection/checkplan/'),
	search: params => wormsApi.get('api/v1/inspection/checkplan/', { params }),
	get: (id) => wormsApi.get('api/v1/inspection/checkplan/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/inspection/checkplan/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/inspection/checkplan/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/inspection/checkplan/', item),
	delete: (id) => wormsApi.delete('api/v1/inspection/checkplan/' + id + '/'),
}
const inspectionCheckplanAsset = {
	list: () => wormsApi.get('api/v1/inspection/checkplan-asset/'),
	search: params => wormsApi.get('api/v1/inspection/checkplan-asset/', { params }),
	get: (id) => wormsApi.get('api/v1/inspection/checkplan-asset/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/inspection/checkplan-asset/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/inspection/checkplan-asset/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/inspection/checkplan-asset/', item),
	delete: (id) => wormsApi.delete('api/v1/inspection/checkplan-asset/' + id + '/'),
}
const inspectionParameterAsset = {
	list: () => wormsApi.get('api/v1/inspection/checkplan-parameter/'),
	search: params => wormsApi.get('api/v1/inspection/checkplan-parameter/', { params }),
	get: (id) => wormsApi.get('api/v1/inspection/checkplan-parameter/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/inspection/checkplan-parameter/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/inspection/checkplan-parameter/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/inspection/checkplan-parameter/', item),
	delete: (id) => wormsApi.delete('api/v1/inspection/checkplan-parameter/' + id + '/'),
}
const inspectionChecklist = {
	list: () => wormsApi.get('api/v1/inspection/checklist/'),
	search: params => wormsApi.get('api/v1/inspection/checklist/', { params }),
	get: (id) => wormsApi.get('api/v1/inspection/checklist/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/inspection/checklist/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/inspection/checklist/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/inspection/checklist/', item),
	delete: (id) => wormsApi.delete('api/v1/inspection/checklist/' + id + '/'),
}
const inspectionChecklistAsset = {
	list: () => wormsApi.get('api/v1/inspection/checklist-asset/'),
	search: params => wormsApi.get('api/v1/inspection/checklist-asset/', { params }),
	get: (id) => wormsApi.get('api/v1/inspection/checklist-asset/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/inspection/checklist-asset/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/inspection/checklist-asset/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/inspection/checklist-asset/', item),
	delete: (id) => wormsApi.delete('api/v1/inspection/checklist-asset/' + id + '/'),
}
const inspectionChecklistParameter = {
	list: () => wormsApi.get('api/v1/inspection/checklist-parameter/'),
	search: params => wormsApi.get('api/v1/inspection/checklist-parameter/', { params }),
	get: (id) => wormsApi.get('api/v1/inspection/checklist-parameter/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/inspection/checklist-parameter/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/inspection/checklist-parameter/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/inspection/checklist-parameter/', item),
	delete: (id) => wormsApi.delete('api/v1/inspection/checklist-parameter/' + id + '/'),
}
// Recurrence
const recurrence = {
	list: () => wormsApi.get('api/v1/schedule/recurrence/'),
	search: params => wormsApi.get('api/v1/schedule/recurrence/', { params }),
	get: (id) => wormsApi.get('api/v1/schedule/recurrence/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/schedule/recurrence/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/schedule/recurrence/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/schedule/recurrence/', item),
	delete: (id) => wormsApi.delete('api/v1/schedule/recurrence/' + id + '/'),
}
const recurrenceTime = {
	list: () => wormsApi.get('api/v1/schedule/recurrence-time/'),
	search: params => wormsApi.get('api/v1/schedule/recurrence-time/', { params }),
	get: (id) => wormsApi.get('api/v1/schedule/recurrence-time/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/schedule/recurrence-time/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/schedule/recurrence-time/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/schedule/recurrence-time/', item),
	delete: (id) => wormsApi.delete('api/v1/schedule/recurrence-time/' + id + '/'),
}
// User
const team = {
	list: () => wormsApi.get('api/v1/user/team/'),
	search: params => wormsApi.get('api/v1/user/team/', { params }),
	get: (id) => wormsApi.get('api/v1/user/team/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/user/team/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/user/team/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/user/team/', item),
	delete: (id) => wormsApi.delete('api/v1/user/team/' + id + '/'),
}
const user = {
	list: () => wormsApi.get('api/v1/user/user/'),
	search: params => wormsApi.get('api/v1/user/user/', { params }),
	get: (id) => wormsApi.get('api/v1/user/user/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/user/user/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/user/user/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/user/user/', item),
	delete: (id) => wormsApi.delete('api/v1/user/user/' + id + '/'),
}
const member = {
	list: () => wormsApi.get('api/v1/user/member/'),
	search: params => wormsApi.get('api/v1/user/member/', { params }),
	get: (id) => wormsApi.get('api/v1/user/member/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/user/member/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/user/member/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/member/', item),
	delete: (id) => wormsApi.delete('api/v1/member/' + id + '/'),
}
// Location
const location = {
	list: () => wormsApi.get('api/v1/location/location/'),
	listAll: () => wormsApi.get('api/v1/location/location/tree/'),
	search: (params) => wormsApi.get('api/v1/location/location/', { params }),
	get: (id) => wormsApi.get('api/v1/location/location/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/location/location/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/location/location/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/location/location/', item),
	delete: (id) => wormsApi.delete('api/v1/location/location/' + id + '/'),
}
// Work
const workTemplate = {
	list: () => wormsApi.get('api/v1/work/template/'),
	search: params => wormsApi.get('api/v1/work/template/', { params }),
	get: (id) => wormsApi.get('api/v1/work/template/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/work/template/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/work/template/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/work/template/', item),
	delete: (id) => wormsApi.delete('api/v1/work/template/' + id + '/'),
}
const workTemplateInstruction = {
	list: () => wormsApi.get('api/v1/work/template-instruction/'),
	search: params => wormsApi.get('api/v1/work/template-instruction/', { params }),
	get: (id) => wormsApi.get('api/v1/work/template-instruction/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/work/template-instruction/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/work/template-instruction/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/work/template-instruction/', item),
	delete: (id) => wormsApi.delete('api/v1/work/template-instruction/' + id + '/'),
}
const workTemplateParameter = {
	list: () => wormsApi.get('api/v1/work/template-parameter/'),
	search: params => wormsApi.get('api/v1/work/template-parameter/', { params }),
	get: (id) => wormsApi.get('api/v1/work/template-parameter/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/work/template-parameter/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/work/template-parameter/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/work/template-parameter/', item),
	delete: (id) => wormsApi.delete('api/v1/work/template-parameter/' + id + '/'),
}
const workPlan = {
	list: () => wormsApi.get('api/v1/work/plan/'),
	search: params => wormsApi.get('api/v1/work/plan/', { params }),
	get: (id) => wormsApi.get('api/v1/work/plan/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/work/plan/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/work/plan/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/work/plan/', item),
	delete: (id) => wormsApi.delete('api/v1/work/plan/' + id + '/'),
	getOrders: (id) => wormsApi.get('api/v1/work/plan/' + id + '/order/'),
	generateOrders: (id) => wormsApi.put('api/v1/work/plan/' + id + '/order/'),
	deleteOrders: (id) => wormsApi.delete('api/v1/work/plan/' + id + '/order/'),
}
const workOrder = {
	list: () => wormsApi.get('api/v1/work/order/'),
	search: params => wormsApi.get('api/v1/work/order/', { params }),
	get: (id) => wormsApi.get('api/v1/work/order/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/work/order/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/work/order/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/work/order/', item),
	delete: (id) => wormsApi.delete('api/v1/work/order/' + id + '/'),
}
const workOrderTask = {
	list: () => wormsApi.get('api/v1/work/order-instruction/'),
	search: params => wormsApi.get('api/v1/work/order-instruction/', { params }),
	get: (id) => wormsApi.get('api/v1/work/order-instruction/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/work/order-instruction/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/work/order-instruction/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/work/order-instruction/', item),
	delete: (id) => wormsApi.delete('api/v1/work/order-instruction/' + id + '/'),
}
const workOrderDetail = {
	list: () => wormsApi.get('api/v1/work/order-detail/'),
	search: params => wormsApi.get('api/v1/work/order-detail/', { params }),
	get: (id) => wormsApi.get('api/v1/work/order-detail/' + id + '/'),
	history: (id) => wormsApi.get('api/v1/work/order-detail/' + id + '/history/'),
	save: (item) => wormsApi.patch('api/v1/work/order-detail/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/work/order-detail/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/work/order-detail/', item),
	delete: (id) => wormsApi.delete('api/v1/work/order-detail/' + id + '/'),
}
const workOrderAssetParameter = {
	list: () => wormsApi.get('api/v1/work/order-asset-parameter/'),
	search: params => wormsApi.get('api/v1/work/order-asset-parameter/', { params }),
	get: (id) => wormsApi.get('api/v1/work/order-asset-parameter/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/work/order-asset-parameter/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/work/order-asset-parameter/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/work/order-asset-parameter/', item),
	delete: (id) => wormsApi.delete('api/v1/work/order-asset-parameter/' + id + '/'),
}
const workAnswer = {
	list: () => wormsApi.get('api/v1/work/answer/'),
	search: params => wormsApi.get('api/v1/work/answer/', { params }),
	get: (id) => wormsApi.get('api/v1/work/answer/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/work/answer/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/work/answer/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/work/answer/', item),
	delete: (id) => wormsApi.delete('api/v1/work/answer/' + id + '/'),
}
const workScope = {
	list: () => wormsApi.get('api/v1/work/scope/'),
	search: params => wormsApi.get('api/v1/work/scope/', { params }),
	get: (id) => wormsApi.get('api/v1/work/scope/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/work/scope/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/work/scope/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/work/scope/', item),
	delete: (id) => wormsApi.delete('api/v1/work/scope/' + id + '/'),
}
const workScopeInstruction = {
	list: () => wormsApi.get('api/v1/work/scope-instruction/'),
	search: params => wormsApi.get('api/v1/work/scope-instruction/', { params }),
	get: (id) => wormsApi.get('api/v1/work/scope-instruction/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/work/scope-instruction/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/work/scopev/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/work/scope-instruction/', item),
	delete: (id) => wormsApi.delete('api/v1/work/scope-instruction/' + id + '/'),
}
const workOrderHeading = {
	search: params => wormsApi.get('api/v1/work/order-heading/', { params }),
}
const workOrderAddendum = {
	search: params => wormsApi.get('api/v1/work/order-addendum/', { params }),
	save: (item) => wormsApi.patch('api/v1/work/order-addendum/' + item.id + '/', item),
}
const addendum = {
	list: () => wormsApi.get('api/v1/invoice/addendum/'),
}
const invoiceHeading = {
	list: () => wormsApi.get('api/v1/invoice/heading/'),
	listAll: () => wormsApi.get('api/v1/invoice/heading/tree/'),
	search: params => wormsApi.get('api/v1/invoice/heading/', { params }),
	get: (id) => wormsApi.get('api/v1/invoice/heading/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/invoice/heading/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/invoice/heading/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/invoice/heading/', item),
	delete: (id) => wormsApi.delete('api/v1/invoice/heading/' + id + '/'),
}
const invoiceCostCenter = {
	list: () => wormsApi.get('api/v1/invoice/costcenter/'),
	search: params => wormsApi.get('api/v1/invoice/costcenter/', { params }),
	get: (id) => wormsApi.get('api/v1/invoice/costcenter/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/invoice/costcenter/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/invoice/costcenter/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/invoice/costcenter/', item),
	delete: (id) => wormsApi.delete('api/v1/invoice/costcenter/' + id + '/'),
}
const invoiceDetail = {
	list: () => wormsApi.get('api/v1/invoice/detail/'),
	search: params => wormsApi.get('api/v1/invoice/detail/', { params }),
	get: (id) => wormsApi.get('api/v1/invoice/detail/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/invoice/detail/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/invoice/detail/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/invoice/detail/', item),
	delete: (id) => wormsApi.delete('api/v1/invoice/detail/' + id + '/'),
}
const invoiceDetailCostCenter = {
	list: () => wormsApi.get('api/v1/invoice/costcenter-detail/'),
	search: params => wormsApi.get('api/v1/invoice/costcenter-detail/', { params }),
	get: (id) => wormsApi.get('api/v1/invoice/costcenter-detail/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/invoice/costcenter-detail/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/invoice/costcenter-detail/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/invoice/costcenter-detail/', item),
	delete: (id) => wormsApi.delete('api/v1/invoice/costcenter-detail/' + id + '/'),
}
const complaintComplaint = {
	list: () => wormsApi.get('api/v1/complaint/complaint/'),
	search: params => wormsApi.get('api/v1/complaint/complaint/', { params }),
	get: (id) => wormsApi.get('api/v1/complaint/complaint/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/complaint/complaint/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/complaint/complaint/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/complaint/complaint/', item),
	merge: (item) => wormsApi.post('api/v1/complaint/complaint/'+ item.parent + '/merge/', item),
	delete: (id) => wormsApi.delete('api/v1/complaint/complaint/' + id + '/'),
}
const invoice = {
	list: () => wormsApi.get('api/v1/invoice/invoice/'),
	search: params => wormsApi.get('api/v1/invoice/invoice/', { params }),
	get: (id) => wormsApi.get('api/v1/invoice/invoice/' + id + '/'),
	save: (item) => wormsApi.patch('api/v1/invoice/invoice/' + item.id + '/', item),
	saveForm: (id, item) => wormsApi.patch('api/v1/invoice/invoice/' + id + '/', item),
	new: (item) => wormsApi.post('api/v1/invoice/invoice/', item),
	delete: (id) => wormsApi.delete('api/v1/invoice/invoice/' + id + '/'),
	changeAllStatus: (item) => wormsApi.patch('api/v1/invoice/invoice/' + item.id + '/status/', item),
	pdf: (id) => wormsApi.get('api/v1/invoice/invoice/' + id + '/pdf/'),
	invoiceWeb: (item) => wormsApi.get('experimental/invoice/'+ item.id +'/report/', item),
	invoicePdf: (item) => wormsApi.get('experimental/invoice/'+ item.id +'/report/pdf/', item, {responseType: 'arraybuffer'})
}

const preference = {
	global: () => wormsApi.get('api/v1/preference/global/'),
	user: () => wormsApi.get('api/v1/preference/user/'),
}
const accountProfile = {
	get: () => wormsApi.get('api/account/profile/'),
	save: (item) => wormsApi.patch('api/v1/user/user/' + item.id + '/profile/', item),
	regDeviceOneSignal: (item) => wormsApi.post('api/v1/user/device/', item),
	register: (item) => wormsApi.post('api/account/register/', item),
	verifyUser: (item) => wormsApi.post('api/account/verify-user/', item),
	verifyRegister: (item) => wormsApi.post('api/account/verify-registration/', item),
	changeEmail: (email) => wormsApi.post('api/account/register-email/', email),
	verifyEmail: (item) => wormsApi.post('api/account/verify-email/', item),
	changePassword: (item) => wormsApi.post('api/account/change-password/', item),
	sendPassword: (login) => wormsApi.post('api/account/send-reset-password-link/', login),
	resetPassword: (item) => wormsApi.post('api/account/reset-password/', item),
}
const statistics = {
	orderRange: (params) => wormsApi.get('api/v1/work/order/statistic/range/', { params }),
	orderCurrent: (current) => wormsApi.get('api/v1/work/order/statistic/current/?current='+current),
	orderToday: (current) => wormsApi.get('api/v1/work/order/statistic/current/'),
	orderCost: (params) => wormsApi.get('api/v1/work/order/statistic/current/costcenter/', { params }),
	orderScope: (params) => wormsApi.get('api/v1/work/order/statistic/current/scope/', { params }),
	avgOrderUser: (params) => wormsApi.get('api/v1/work/order/statistic/current/', { params }),
	jsonChart: (params) => wormsApi.get('api/v1/work/order/chart/timeline/'+params.category+'/', { params }),
	jsonAssetChart: (params) => wormsApi.get('api/v1/chart/asset/timeline/', { params }),
	jsonAssetSystemChart: (params) => wormsApi.get('api/v1/chart/asset/timeline/system/', { params }),
	jsonVendorStatusChart: (params) => wormsApi.get('api/v1/work/order/chart/timeline/status/', { params }),
}
export {
	wormsApi,
}
export default {
	// Asset
	assetSystem,
	assetAsset,
	assetGenericParameter, 
	assetGeneric,
	assetAssetParameter,

	// Unit
	unitUnit,
	unitMeasurement, 

	// Inspection
	inspectionCheckplan,
	inspectionCheckplanAsset,
	inspectionParameterAsset,
	inspectionChecklist,
	inspectionChecklistAsset,
	inspectionChecklistParameter,

	// Recurrence
	recurrence,
	recurrenceTime,
	
	// Location
	location,

	// Claim
	invoice,


	preference,
	team,
	user,
	member, 
	// compCheck,
	accountProfile,
	workTemplate,
	workTemplateInstruction,
	workTemplateParameter,
	workPlan,
	
	workOrder,
	workOrderTask,
	workOrderDetail,
	workOrderAssetParameter,
	workOrderHeading,
	workOrderAddendum,
	workAnswer,
	workScope,
	workScopeInstruction,
	addendum,
	invoiceHeading,
	invoiceCostCenter,
	invoiceDetail,
	invoiceDetailCostCenter,
	complaintComplaint,

	statistics
}