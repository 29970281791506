<template>
    <div>
        <v-list nav class="mt-4 text-left">
            <v-list-item  link v-for="(menu, index) in menuSelected" v-bind:key="index"  :to="menu.link">
                <v-list-item-action>
                    <v-icon>{{menu.icon}}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{menu.name}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>
<script>
	export default {
		  components: {
		  },
		data: () => ({
            indexName: ['', 'one', 'two', 'three', 'four', 'five'],
            globalPref: [],
            adminSubMenu: {
                dashboard: [
                    {
                        name: 'Current',
                        link: '/',
                        icon: 'mdi-home'
                    },
                    {
                        name: 'Order',
                        link: '/timeline-order',
                        icon: 'mdi-timeline-clock'
                    },
                    {
                        name: 'Asset',
                        link: '/timeline-asset',
                        icon: 'mdi-timeline'
                    },
                    {
                        name: 'Vendor',
                        link: '/timeline-vendor',
                        icon: 'mdi-account-network'
                    },
                ],
                user: [
                    {
                        name: 'User',
                        link: '/users',
                        icon: 'mdi-account'
                    },
                    // {
                    //     name: 'Team',
                    //     link: '/teams',
                    //     icon: 'mdi-account-multiple'
                    // },
                ],
                location: [
                    {
                        name: 'Location',
                        link: '/locations',
                        icon: 'mdi-map-marker'
                    },
                ],
                asset: [
                    {
                        name: 'Asset',
                        link: '/assets',
                        icon: 'mdi-application'
                    },
                    {
                        name: 'Generic',
                        link: '/generics',
                        icon: 'mdi-application-settings'
                    },
                    {
                        name: 'System',
                        link: '/systems',
                        icon: 'mdi-cog-box'
                    },
                ],
                check: [
                    {
                        name: 'List',
                        link: '/checklists',
                        icon: 'mdi-order-bool-ascending-variant'
                    },
                    {
                        name: 'Plan',
                        link: '/checkplans',
                        icon: 'mdi-order-bool-ascending'
                    },
                ],
                complaint: [
                    {
                        name: 'Complaint',
                        link: '/work/complaints',
                        icon: 'mdi-bullhorn'
                    },
                ],
                work: [
                    {
                        name: 'Order',
                        link: '/work/orders',
                        icon: 'mdi-briefcase'
                    },
                    // {
                    //     name: 'Plan',
                    //     link: '/work/plans',
                    //     icon: 'mdi-check-network'
                    // },
                    {
                        name: 'Template',
                        link: '/work/templates',
                        icon: 'mdi-file-cabinet'
                    },
                    {
                        name: 'Scope',
                        link: '/work/scopes',
                        icon: 'mdi-format-list-checkbox'
                    },
                    {
                        name: 'Record',
                        link: '/work/records',
                        icon: 'mdi-account-voice'
                    },
                    {
                        name: 'Heading',
                        link: '/work/headings',
                        icon: 'mdi-format-title'
                    },
                    {
                        name: 'Cost Center',
                        link: '/work/cost-centers',
                        icon: 'mdi-cash-marker'
                    },
                ],
                invoice: [
                    {
                        name: 'Make Invoice',
                        link: '/make-invoices',
                        icon: 'mdi-receipt-text-plus-outline'
                    },
                    {
                        name: 'Invoice',
                        link: '/invoices',
                        icon: 'mdi-receipt'
                    },
                ],
                recurrence: [
                    {
                        name: 'Recurrence',
                        link: '/recurrences',
                        icon: 'mdi-update'
                    },
                ],
                recurrence: [
                    {
                        name: 'Recurrence',
                        link: '/recurrences',
                        icon: 'mdi-update'
                    },
                ],
                unit: [
                    {
                        name: 'Unit',
                        link: '/units',
                        icon: 'mdi-tape-measure'
                    },
                    {
                        name: 'Measurement',
                        link: '/measurements',
                        icon: 'mdi-ruler'
                    },
                ],
                statistic: [
                    {
                        name: 'Order Statistic',
                        link: '/order-statistic',
                        icon: 'mdi-chart-areaspline-variant'
                    },
                ],
            },
            customerMenu: [
                {
                    name: 'Complaint',
                    link: '/work/complaints',
                    icon: 'mdi-bullhorn'
                },
            ],
            vendorMenu: [
                {
                    name: 'Complaint',
                    link: '/work/complaints',
                    icon: 'mdi-bullhorn'
                },
                {
                    name: 'Record',
                    link: '/work/records',
                    icon: 'mdi-account-voice'
                },
            ],
            staffMenu: [
                {
                    name: 'Dashboard',
                    link: '/',
                    icon: 'mdi-home'
                },
                {
                    name: 'Timeline',
                    link: '/timeline',
                    icon: 'mdi-timeline'
                },
                {
                    name: 'Complaint',
                    link: '/work/complaints',
                    icon: 'mdi-bullhorn'
                },
                {
                    name: 'Record',
                    link: '/work/records',
                    icon: 'mdi-account-voice'
                },
                {
                    name: 'Check',
                    link: '/checklists',
                    icon: 'mdi-order-bool-ascending-variant'
                },
            ],
            managerMenu: [
                {
                    name: 'Dashboard',
                    link: '/',
                    icon: 'mdi-home'
                },
                {
                    name: 'Timeline',
                    link: '/timeline',
                    icon: 'mdi-timeline'
                },
                {
                    name: 'Complaint',
                    link: '/work/complaints',
                    icon: 'mdi-bullhorn'
                },
                {
                    name: 'Work',
                    link: '/work/orders',
                    icon: 'mdi-briefcase'
                },
                {
                    name: 'Make Claim',
                    link: '/make-invoices',
                    icon: 'mdi-receipt-text-plus-outline'
                },
                {
                    name: 'Claim',
                    link: '/invoices',
                    icon: 'mdi-receipt'
                },
                {
                    name: 'Check',
                    link: '/checklists',
                    icon: 'mdi-order-bool-ascending-variant'
                },
                {
                    name: 'Recurrence',
                    link: '/recurrences',
                    icon: 'mdi-update'
                },
            ]
        }),
        computed: {
			maxLevel: function() {
				if (this.globalPref.length > 0) {
					// return (this.globalPref.find(pref => pref.name === 'maximum_level').value);
				} else {
                    return 5;
                }
            },
			profileRole:{
                get: function () {
                    return this.$store.getters.profileRole;
                },
            },
            menuSelected: function() {
                if (this.profileRole == 'SYSADMIN') {
                    return this.adminSubMenu[this.$route.meta.category];
                }
                if (this.profileRole == 'CUSTOMER') {
                    return this.customerMenu;
                }
                if (this.profileRole == 'VENDOR') {
                    return this.vendorMenu;
                }
                if (this.profileRole == 'STAFF') {
                    return this.staffMenu;
                }
                if (this.profileRole == 'MANAGER') {
                    return this.managerMenu;
                }
            }
        },
        methods: {
            getGlobalPref() {
                this.getGlobalPreferences().then(res => this.globalPref = res);
            },
			async getGlobalPreferences() {
                try {
                    const res = await this.$api.preference.global();
                    return res.data;
                } catch (error) {
                } finally {
                }
            },
            getLevelName(levelLabel) {
                if ( this.globalPref.length > 0 && levelLabel !== undefined) {
                    return this.globalPref.find(pref => pref.name === levelLabel).value;
                }
            },
        },
		mounted: function () {
			this.getGlobalPref();
        },
		watch: {			
			authed(status) {
				if (status == true) {
					this.getGlobalPref();
				}
			},
		}
    }
</script>